<template>
  <div class="home" id="home" name="home">
    <div class="contain">
      <!-- 首页头部 -->
      <div class="search-container">
        <div class="left">
          <img :src="require('@/assets/logo.png')" alt class="img" @click="clickLogo" />
          <div class="dream">国内正规技能交易平台</div>
        </div>
        <Head></Head>
        <el-popover class="right"
                    width="120"
                    placement="top"
                    trigger="hover">
          <el-image :src="require('@/assets/imgs/wx-group.png')"></el-image>
          <img :src="require('@/assets/imgs/wx-group.png')" slot="reference" alt class="img" />
        </el-popover>
      
      </div>
      <!-- 导航 -->
      <NavBar></NavBar>
      
      <!-- 热门服务 -->
      <div class="Lists">
        <div class="title">
          <span class="tit_head">大家都在雇</span>
          <span class="more" @click="GetHall">
            查看更多
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <div class="data_list">
          <el-row v-for="(page, index) of pages" :key="index" type="flex" :gutter="22">
            <el-col :span="6" v-for="(item) of page" :key="item.id">
              <list1 :items="item" :Show="false" @GetClick="GetDetailsPage"></list1>
            </el-col>
          </el-row>
        </div>
      
      </div>
      
      <!-- 行业大咖 -->
      <div class="Lists">
        <div class="title">
          <span class="tit_head">行业大咖</span>
          <!-- <span class="more">
            查看更多
            <i class="el-icon-arrow-right"></i>
          </span>-->
        </div>
        <!-- <List1 :DataList="DataList" :Config="Config" :Num="Num" /> -->
        <div class="industryList">
          <list3 :list="ServiceHome" />
        </div>
      </div>
      
      <!-- 活动专区 -->
      <div class="Lists">
        <div class="title">
          <span class="tit_head">活动专区</span>
          <!-- <span class="more">
            查看更多
            <i class="el-icon-arrow-right"></i>
          </span>-->
        </div>
        <div class="data_list">
          <!--          <list2 :items="item" v-for="(item, index) in activityList" :key="index" />-->
          <el-row type="flex" :gutter="22">
            <el-col :span="6" v-for="(item) of activityList" :key="item.id">
              <list2 :items="item"></list2>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Banner from "@/components/Swiper/index.vue";
import list1 from "@/components/List/components/list1.vue";
import list2 from "@/components/List/components/list2.vue";
import list3 from "@/components/List/components/list3.vue";
// import List1 from "@/components/List/List1.vue";
import Head from "./components/head.vue";
import NavBar from "./components/NavBar.vue";

export default {
  data() {
    return {
      List: [],
      DataList: [],
      Num: 4,
      Config: {
        width: "1440px",
        height: "473px",
      },
      activityList: [
        {
          title: "新人专享福利",
          startTime: "2023-08-01",
          endTime: "2025-08-01",
          id: 1,
          img: require("@/assets/imgs/huodong/icon1.png"),
        },
        {
          title: "充值预存就送",
          startTime: "2023-08-01",
          endTime: "2025-08-01",
          id: 2,
          img: require("@/assets/imgs/huodong/icon2.png"),
        },
        {
          title: "优惠服务专区",
          startTime: "2023-08-01",
          endTime: "2025-08-01",
          id: 3,
          img: require("@/assets/imgs/huodong/icon3.png"),
        },
        {
          title: "雇用就送",
          startTime: "2023-08-01",
          endTime: "2025-08-01",
          id: 4,
          img: require("@/assets/imgs/huodong/icon4.png"),
        },
      ],
      ServiceHome: [],
    };
  },
  components: {
    // Banner,
    list2,
    // List1,
    list1,
    list3,
    Head,
    NavBar,
  },
  created() {
    this.getByUserId();
    this.GetServiceHome();
  },
  computed: {
    pages() {
      const pages = []
      if (!this.List || !this.List.length) return pages
      this.List.forEach((item, index) => {
        const page = Math.floor(index / 4) // 代表4条为一行，随意更改
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    }
  },
  mounted() {
    // 接收首页 传值过来的数据
    
    this.$parent.$parent.HeardsShow = false;
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    this.$parent.$parent.HeardsShow = true;
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    clickLogo() {
      this.$router.push({ name: 'Home' })
    },
    handleScroll() {
      let height = document.documentElement.scrollTop;
      if (height > 140) {
        this.$parent.$parent.HeardsShow = true;
      } else {
        this.$parent.$parent.HeardsShow = false;
      }
    },
    // 大家都在雇
    getByUserId() {
      this.$axios
          .get("/app-api/hiring/push-service/popular", {params: {num: 8}})
          .then((res) => {
            if (res.data.code === 0) {
              this.List = res.data.data;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 行业大咖
    GetServiceHome() {
      let url = "/app-api/hiring/service-provider-info/popular";
      this.$axios
          .get(url, { params: { num: 6 } })
          .then((res) => {
            if (res.data.code === 0) {
              this.ServiceHome = res.data.data;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 进入详情页面
    GetDetailsPage(Ids) {
      this.$router.push({ path: "/ServiceHall/Details", query: { id: Ids } });
    },
    GetHall() {
      this.$router.push({ name: "ServiceHall" });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  background: #f7f9fa;
}

.search-container {
  margin-bottom: 30px;
  position: relative;
  
  .left {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    
    .img {
      cursor: pointer;
      height: 50px;
    }
    
    .dream {
      color: #575757;
      margin-left: 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  
  .right {
    position: absolute;
    top: 0;
    right: 50px;
    
    .img {
      cursor: pointer;
      height: 80px;
    }
  }
  
}

.home {
  width: 100%;
  // min-width: 1920px;
}

.el-row + .el-row {
  margin-top: 20px;
}

.contain {
  width: 1440px;
  margin: 0 auto;
  
  .industryList {
    display: flex;
  }
  
  .Lists {
    // width: 1440px;
    margin: 50px auto 0;
    
    .data_list {
      //display: flex;
      //flex-wrap: wrap;
    }
    
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      
      .tit_head {
        font-size: 28px;
        color: #000;
      }
      
      .more {
        font-size: 16px;
        color: #fc3737;
        cursor: pointer;
      }
    }
    
    .items {
      margin-right: 20px !important;
      
      &:nth-child(4n) {
        margin-right: 0 !important;
      }
    }
  }
}

</style>
