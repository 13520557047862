<template>
  <div class="content">
    <!-- <div class="imgs">
      <img src="" alt="" />
    </div>-->
    <div class="search">
      <Search></Search>
      <div class="title_list">
        <span v-for="(item, index) in palntList" :key="index" class="tit_item" @click="GetHall(item)">{{ item.items_name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Search from "@/components/Search/index.vue";

export default {
  data() {
    return {
      palntList: [],
    };
  },
  components: {
    Search,
  },
  mounted() {
    this.GetPopularService();
  },
  methods: {
    // 获取热门服务
    GetPopularService() {
      this.$axios
        .get(`/app-api/hiring/service-items/recommend`, {
          params: {
            num: 6
          }
        })
        .then((res) => {
          if (res.data.code === 0) {
            let data = res.data.data;
            for (let x = 0; x < data.length; x++) {
              if (data[x].children) {
                for (let y = 0; y < data[x].children.length; y++) {
                  if (
                    data[x].children[y].pid != 0 &&
                    data[x].children[y].recommend == 1
                  ) {
                    this.palntList.push(data[x].children[y]);
                  }
                }
              }
            }
          } else {
            this.palntList = [];
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    GetHall(item) {
      this.$router.push({
        name: "ServiceHall",
        params: {
          type: "categoriesId", //类目
          id: item.pid,
          ids: item.id,
          value: item.items_name,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.content {
  // display: flex;
  // justify-content: space-between;
  .imgs {
    display: flex;
    align-items: center;
    img {
      width: 216px;
      height: 66px;
      margin: auto 0;
    }
  }

  .search {
    /deep/ .Search {
      width: 841px !important;
    }
    .title_list {
      font-size: 14px;
      color: #fc9191;
      text-align: left;
      width: 841px;
      margin: 10px auto 0;
      .tit_item {
        margin-right: 25px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
