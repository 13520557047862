<template>
  <div class="Banner" :style="{ width: Config.width }">
    <el-carousel :style="{ height: Config.height }">
      <el-carousel-item v-for="item in carousel" :key="item.id">
        <img
          :style="{ height: Config.height, width: Config.width }"
          :src="item.url"
          alt=""
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carousel: [],
      Height: null,
    };
  },
  props: {
    Config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    if (this.Config.carousel) {
      this.carousel = this.Config.carousel;
    }
  },
};
</script>
<style lang="less">
.Banner {
  margin: 0 auto;

  img {
    height: 100%;
  }

  .el-carousel__container {
    height: 100% !important;
    background: #b1b1b1;
  }
}
</style>