<template>
  <div class="content">
    <div class="nav_list">
      <!-- region 热门服务 -->
      <div class="hot_left">
        <div class="hot">热门服务</div>
        <div class="plant">
          <div class="plant-row">
            <span @click="getHall({ pid:2, id:26, itemsName:'视频剪辑' })">视频剪辑</span>
            <span @click="getHall({ pid:1, id:4, itemsName:'商标设计' })">商标设计</span>
          </div>
          <div class="plant-row">
            <span @click="getHall({ pid:13, id:53, itemsName:'直播助手' })">直播助手</span>
            <span @click="getHall({ pid:111, id:112, itemsName:'跨境开店' })">跨境开店</span>
          </div>
        </div>
        <div class="hot_list">
          <div class="hot_item" @mouseover="showMore(item.id)" v-for="(item, index) in hotList" :key="index"
               :class="{ active: active === item.id }">
            <img :src="item.logoImg" alt />
            <span>{{ item.itemsName }}</span>
          </div>
        </div>
      </div>
      <div class="look_more" v-if="show" @mouseenter="enterMore" @mouseleave="leaveMore()">
        <div class="more_title">{{ title }}</div>
        <div class="more_content">
          <span class="more_label" v-for="item in labelList" :key="item.id" @click="getHall(item)">
            {{ item.itemsName }}
          </span>
        </div>
      </div>
      <!-- endregion -->
      <!-- region 中间轮播 -->
      <div class="swiper">
        <Banner :Config="Config"></Banner>
      </div>
      <!-- endregion -->
      <!-- region 右侧信息 -->
      <div class="login">
        <!-- 头像 -->
        <div class="avatar_mess">
          <div class="avatar" v-if="logged">
            <el-avatar :size="92" :src="UserInfo.avatarImg"></el-avatar>
            <div class="vip">VIP{{ isProvider ? ServiceInfo.providerGrade : 0 }}</div>
            <div class="name">{{ user.name }}</div>
            <div class="join-time" v-if="user.passTime">入驻时间：{{ user.passTime }}</div>
          </div>
          <div class="avatar" v-else>
            <el-avatar :size="92" :src="require('@/assets/imgs/user/avatar.png')" class="avater"></el-avatar>
            <div class="vip">VIP0</div>
          </div>
        </div>
        <!-- region 是服务商 -->
        <div class="info-row" v-if="logged && isProvider">
          <div class="info-item" v-if="ServiceInfo.providerType === 2">
            <div class="item-content">
              <i class="el-icon-office-building"></i>
            </div>
            <div class="item-label">企业</div>
          </div>
          <div class="info-item" v-else>
            <div class="item-content">
              <i class="icon iconfont icon-provider"></i>
            </div>
            <div class="item-label">个人</div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="info-item">
            <div class="item-content">
              <i class="el-icon-location-outline"></i>
            </div>
            <el-tooltip :content="'所在地区：' + user.address" placement="top" effect="light">
              <div class="item-label">{{ user.address }}</div>
            </el-tooltip>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="info-item" v-if="ServiceInfo.providerType === 2">
            <div class="item-content">
              <el-tooltip content="营业执照" placement="top" effect="light">
                <i class="icon iconfont icon-yingyezhizhao" />
              </el-tooltip>
            </div>
            <div class="item-label">资质认证</div>
          </div>
          <div class="info-item" v-else>
            <div class="item-content">
              <el-tooltip content="身份证" placement="top" effect="light">
                <i class="icon iconfont icon-id2" />
              </el-tooltip>
            </div>
            <div class="item-label">资质认证</div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="info-item">
            <div class="item-content">
              <el-tooltip :content="'保证金' + user.deposit" placement="top" effect="light">
                <i class="icon iconfont icon-safe"></i>
              </el-tooltip>
            </div>
            <div class="item-label">平台保证</div>
          </div>
        </div>
        <!-- endregion -->
        <!-- region 不是服务商 -->
        <div class="info-row" v-if="logged && !isProvider">
          <div class="info-item">
            <div class="item-content">{{ orderCountInfo.orderWaitingCheck || 0}}</div>
            <div class="item-label">待验收</div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="info-item">
            <div class="item-content">{{ orderCountInfo.orderWaitingWorking || 0 }}</div>
            <div class="item-label">待工作</div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="info-item">
            <div class="item-content">{{ orderCountInfo.orderWaitingPay || 0 }}</div>
            <div class="item-label">待支付</div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="info-item">
            <div class="item-content">{{ orderCountInfo.orderWaitingEvaluate || 0 }}</div>
            <div class="item-label">待评价</div>
          </div>
        </div>
        <!-- endregion -->
        <div class="hello" v-if="!logged">HI! 你好</div>
        <!-- 登录注册 -->
        <div class="login_btn" v-if="!logged">
          <el-button class="main" @click="clickLogin">立即登录</el-button>
          <el-button @click="clickRegister">立即注册</el-button>
        </div>
        <!-- 记录收藏 -->
        <div class="ability">
          <div class="ability_tit">
            <span class="title">最新公告</span>
            <el-link @click="getNews" :underline="false">更多<i class="el-icon-arrow-right"></i></el-link>
          </div>
          <div class="news-item" v-for="(item, index) in newsList" :key="index"
               @click="clickNews(item)">
            <span class="news-content">{{ item.newsTitle }}</span>
            <span class="news-time">{{ moment(item.updateTime).format('MM-DD') }}</span>
          </div>
        </div>
        
        <div class="bottom" v-if="logged">
          <el-divider class="first-divider"></el-divider>
          <el-link :underline="false" @click="clickServiceHome">我的主页</el-link>
          <el-divider direction="vertical"></el-divider>
          <el-link :underline="false" @click="clickGetUser">个人中心</el-link>
        </div>
      </div>
      <!-- endregion -->
    </div>
  </div>
</template>
<script>
import Banner from "@/components/Swiper/Banner.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { USER_SERVICE_STATE } from "@/constants/service";

export default {
  data() {
    return {
      hotList: [],
      labelList: [],
      Config: {
        width: "842px",
        height: "505px",
        carousel: [],
      },
      show: false,
      active: null,
      isEnter: "",
      title: "",
      
      newsList: [], //新闻列表
      
      pageNum: 1,
      pageSize: 4,
      newsType: 1,
      UserInfo: "",
      ServiceInfo: "",
      orderCountInfo: '',
    };
  },
  components: {
    Banner,
  },
  created() {
    this.getBannerList();
    this.getNewsList();
    this.getRecommendService();
    if (this.$store.getters.getUser) {
      this.UserInfo = this.$store.getters.getUser;
      if (USER_SERVICE_STATE.CHECK_PASS.value === this.$store.getters.getUser.serviceStatus)
        this.getProviderInfo();
      // 不是服务商，获取订单数据
      if (USER_SERVICE_STATE.CHECK_PASS.value !== this.$store.getters.getUser.serviceStatus)
        this.getOrderCountInfo();
    }
  },
  mounted() {
  
  },
  computed: {
    ...mapGetters(["getUser"]),
    user() {
      let item = this.ServiceInfo || {}
      if (!item.deposit) item.deposit = 0
      item.deposit = item.deposit.toFixed(0)
      let cityList = this.$store.getters.getCity;
      if (cityList && cityList.length) {
        let tmp = cityList.find(province => province.id === item.providerProvince)
        if (tmp)  {
          item.provinceName = tmp.name
          tmp = tmp.children.find(city => city.id === item.providerCity)
          if (tmp) item.cityName = tmp.name
          item.address = item.cityName
        }
      }
      if (item.passTime) item.passTime = moment(item.passTime).format('YYYY-MM-DD')
      item.name = item.providerName ? item.providerName : this.UserInfo.nickName
      return item
    },
    logged() {
      return this.UserInfo && this.UserInfo.id
    },
    isProvider() {
      return this.ServiceInfo && this.ServiceInfo.id
    }
  },
  watch: {
    // 获取vuex的登录状态
    getUser: function (val) {
      this.getNewsList();
      if (!val.id) {
        this.UserInfo = "";
      } else {
        this.UserInfo = this.$store.getters.getUser;
        this.getProviderInfo();
      }
    },
  },
  methods: {
    ...mapActions(["setShowLogin", "setShowRegister", 'setAd']),
    moment,
    clickLogin() {
      this.setShowLogin(true);
      this.setShowRegister(false);
      this.$router.push({ name: 'login' })
    },
    clickRegister() {
      this.setShowLogin(false);
      this.setShowRegister(true);
      this.$router.push({ name: 'login' })
    },
    // 获取服务商信息
    getProviderInfo() {
      let url =
          "/app-api/hiring/service-provider-info/get/user?userId=";
      let ids = this.$store.getters.getUser.id;
      this.$axios
          .get(url + ids)
          .then((res) => {
            if (res.data.code === 0) {
              this.ServiceInfo = res.data.data;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 获取服务商信息
    getOrderCountInfo() {
      let url = "/app-api/hiring/service-order/statistics";
      this.$axios
          .get(url, {
            params: {
              userId: this.$store.getters.getUser.id
            }
          })
          .then((res) => {
            if (res.data.code === '200') {
              this.orderCountInfo = res.data.data;
              if (!this.orderCountInfo) {
                this.orderCountInfo = {
                
                }
              }
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    getNewsList() {
      let url = "/app-api/hiring/news/page";
      let Ids = `?pageNo=${this.pageNum}&pageSize=${this.pageSize}&newsType=${this.newsType}`;
      this.$axios.get(url + Ids).then((res) => {
        this.newsList = res.data.data.list;
      });
    },
    getRecommendService() {
      let url = "/app-api/hiring/service-items/recommend";
      this.$axios.get(url, {
        params: {
          num: 6
        }
      }).then((res) => {
        this.hotList = res.data.data;
      });
    },
    // 获取banner图
    getBannerList() {
      let url = "/app-api/hiring/banner/page";
      this.$axios
          .get(url, {params: {pageNo: 1,  pageSize: 5}})
          .then((res) => {
            if (res.data.code === 0) {
              res.data.data.list.forEach((item) => {
                this.Config.carousel.push({
                  id: item.id,
                  url: item.url,
                });
              });
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    //
    showMore(id) {
      if (id) {
        this.hotList.forEach((item) => {
          if (id === item.id) {
            this.labelList = item.children;
            this.title = item.itemsName;
          }
        });
      }
      this.active = id;
      this.show = true;
      this.isEnter = id;
    },
    enterMore() {
      if (this.isEnter) {
        this.active = this.isEnter;
      }
      this.show = true;
    },
    leaveMore() {
      this.active = null;
      this.show = false;
    },
    // 跳转到服务大厅 类目参数接收
    getHall(i) {
      this.$router.push({
        name: "ServiceHall",
        params: {
          type: "categoriesId", //类目
          id: i.pid,
          ids: i.id,
          value: i.itemsName,
        },
      });
    },
    clickNews(item) {
      this.$router.push({ path: "/News/Details", query: { id: item.id } });
    },
    // 进入主页
    clickServiceHome() {
      this.$router.push({ path: "/UserInfo/UserHome" });
    },
    // 个人信息
    clickGetUser() {
      this.$router.push({ path: "/UserInfo/PersonInfo" });
    },
    getNews() {
      this.$router.push({ path: "/News" });
    },
  },
};
</script>
<style scoped lang="less">
.content {
  // 头部导航
  .nav_list {
    // height: 55px;
    display: flex;
    justify-content: space-between;
    position: relative;
    // 查看分类
    .look_more {
      position: absolute;
      left: 285px;
      top: 0;
      width: 845px;
      height: 505px;
      // box-shadow: 3px 4px 4px 1px #e2f4ea;
      background-color: #fff;
      z-index: 100;
      margin-left: 14px;
      
      .more_title {
        padding: 20px 30px;
        border-bottom: 1px solid #dfe3ea;
        font-size: 20px;
        font-weight: bold;
      }
      
      .more_content {
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        
        .more_label {
          padding: 8px 13px;
          margin-right: 15px;
          font-size: 18px;
          margin-bottom: 15px;
          cursor: pointer;
          border-radius: 5px;
          color: #888888;
          
          &:hover {
            color: #fc3737;
            background-color: #fc373719;
          }
        }
      }
    }
    
    .hot_left {
      height: 505px;
      z-index: 10;
      transition: height 0.3s;
      overflow: hidden;
      justify-content: space-between;
      background: #ffffff;
      // &:hover {
      //   height: 505px;
      // }
      
      .plant {
        padding: 0 40px;
        color: #888888;
        
        .plant-row {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          
          span {
            border-radius: 4px;
            border: 1px solid #888888;
            padding: 3px 5px;
            font-size: 18px;
            cursor: pointer;
            
            &:hover {
              border: 1px solid #fff;
              background-color: #fc3737;
              color: #fff;
            }
          }
        }
      }
      
      .hot_list {
        .hot_item {
          padding: 0 40px;
          height: 45px;
          line-height: 45px;
          color: #888;
          font-size: 18px;
          cursor: pointer;
          margin: 10px 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &.active {
            background-color: #fc3737;
            color: #fff;
          }
          img {
            width: 20px;
          }
          span {
            flex: 1;
            margin-left: 15px;
          }
        }
      }
    }
    
    .hot {
      height: 65px;
      width: 285px;
      background-color: #fc3737;
      color: #fff;
      font-weight: bold;
      font-size: 22px;
      line-height: 65px;
      text-align: center;
    }
    
    .list {
      height: 100%;
      line-height: 55px;
      
      .list_item {
        font-size: 18px;
        padding: 0 25px;
        color: #888;
        cursor: pointer;
        
        &:hover {
          color: #fc3737;
        }
      }
    }
  }
  
  // 下边内容
  
  .hot_serve {
    width: 285px;
    height: 434px;
  }
  
  // 中间轮播
  .swiper {
    height: 505px;
    width: 842px;
  }
  
  // 右侧信息
  .login {
    width: 285px;
    height: 505px;
    overflow: hidden;
    // padding: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 1px #e2eef4;
    // border-radius: 8px 8px 8px 8px;
    position: relative;
    
    
    .avatar_mess {
      // width: 92px;
      margin: 30px auto 15px;
      padding: 0 10px;
      box-sizing: border-box;
      text-align: center;
      
      .avatar {
        position: relative;
      }
      .vip {
        position: absolute;
        text-align: center;
        line-height: 23px;
        font-size: 12px;
        // bottom: -4px;
        top: 77px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 63px;
        height: 20px;
        border-radius: 2px;
        background: linear-gradient(132deg, #1e1b16 0%, #3f372e 100%);
        color: #e3cfa1;
      }
    }
    .hello {
      text-align: center;
      margin: 20px 0 30px;
      font-size: 16px;
      font-weight: bold;
    }

    .login_btn {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      .el-button {
        width: 120px;
      }
      .main {
        background-color: #fc3737;
        color: #fff
      }
    }
    
    .ability {
      margin-top: 20px;
      margin-bottom: 50px;
      padding: 0 20px;
      color: #999999;
      
      .ability_tit {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin: 15px 0;
        .title {
          color: #000000;
          font-weight: bold;
        }
      }
      
      .news-item {
        font-size: 14px;
        margin: 10px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        
        &:hover {
          color: #fc3737;
        }
      }
    }
  }
}

.join-time {
  font-size: 14px;
  color: #999999;
}
.join-time,.name {
  margin: 12px 0;
}

.info-row {
  display: flex;
  justify-content: space-evenly;
  
  
  .el-divider--vertical {
    height: 40px;
    margin: 0;
  }
  
  .info-item {
    font-size: 14px;
    width: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    .item-content {
      color: #fc3737;
      
      i {
        font-size: 18px;
      }
    }
    
    .item-label {
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #999999;
    }
  }
}

.bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  
  .first-divider {
    flex-basis: 100%;
  }
  
  .el-divider {
    margin: 0;
  }
  
  .el-divider--vertical {
    height: 48px;
  }
  
  .el-link {
    font-size: 16px;
  }
}
</style>
