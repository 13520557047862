<template>
  <div class="items" :class="Num == 4 ? 'items_num' : 'items_num4'" @click="GetClick">
    <div class="image" style="cursor: pointer">
      <el-image style="width: 100%; height: 100%; border-radius: 8px 8px 0px 0px" :src="items.img"></el-image>
    </div>

    <div class="card-info">
      <div class="title">{{ items.title }}</div>
      <div class="time">活动时间：{{ items.startTime }}~{{ items.endTime }}</div>
      <div class="btns">
        <span class="btn">进入活动</span>
      </div>
    </div>
  </div>
</template>
<script>
// list1 需要判断排版数量
export default {
  props: {
    items: {
      type: Object,
      default: () => {
        return {};
      },
    },
    Num: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      id: "",
      userId: "",
    };
  },
  mounted() {
    this.id = this.items.id;
    this.userId = this.$store.getters.getUser.id;
  },

  methods: {
    GetClick() {
      this.$emit("GetClick", this.items.id);
    },
  },
};
</script>
<style lang="less" scoped>
.items {
  width: 341px;
  height: 399px;
  background: #ffffff;
  border-radius: 12px;
  margin-right: 25px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.items_num {
  &:last-of-type {
    margin-right: 0 !important;
  }
}

.items_num4 {
  &:nth-child(4n) {
    margin-right: 0 !important;
  }

  &:nth-child(n + 5) {
    margin-top: 40px !important;
  }
}

.image {
  width: 100%;
  height: 222px;
  background: #adadad;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;

  img {
    width: 100%;
    height: 100%;
  }

  /deep/ .el-image__error {
    color: #ffffff !important;
    background: none !important;
  }

  /deep/ .el-image__placeholder {
    background: none !important;
  }
}

.card-info {
  width: 100%;
  padding: 20px 18px 0;
  box-sizing: border-box;
  height: calc(100% - 222px);
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  border-bottom: 1px solid #ddd;
  .title {
    font-size: 20px;
    margin-bottom: 18px;
    color: #000;
    // font-weight: bold;
  }
  .time {
    font-size: 16px;
    margin-bottom: 18px;
    color: #999999;
  }
  .btns {
    .btn {
      font-size: 16px;
      padding: 5px 15px;
      border: 1px solid #fc3737;
      color: #fc3737;
      cursor: pointer;
    }
  }
}
</style>
